// MAINNET
import contractAbi from "./ABI/contractAbiMainnet.json";
import tokenAbi from "./ABI/tokenAbiMainnet.json";

// testnet
// import contractAbi from "./ABI/contractAbi.json";
// import tokenAbi from "./ABI/tokenAbi.json";

// TESTNET
// export const supportedChainid = 97;
// export const supportedExplorer = "https://testnet.bscscan.com/";
// export const supportedRPCurl = "https://data-seed-prebsc-1-s1.binance.org:8545";
// export const supportedSymbol = "BNB";
// export const supportedNetworkName = "Binance Testnet";

// export const CONTRACT_ADDRESS = "0xe696a31b4A295C113b6826dcD43c77F76386C549";
// export const CONTRACT_ABI = contractAbi;

// export const TOKEN_CONTRACT_ADDRESS = "0xC6B4F375375B14ed742ED0BBf83e74952Fe015e3";
// export const TOKEN_CONTRACT_ABI = tokenAbi;


// export const TOKEN_SYMBOL = "DAI";
// export const TOKEN_DECIMALS = 18;
// export const WEBSITE_URL = "https://myreview.website/Trade/";
// export const WEBSITE_URL = "http://localhost:3000/Trade/";
// export const API_URL = "https://myreview.website/Trade/api/";

// MAINNET
export const supportedChainid = 56;
export const supportedExplorer = "https://bscscan.com/";
export const supportedRPCurl = "https://bsc-dataseed.binance.org/";
export const supportedSymbol = "BNB";
export const supportedNetworkName = "Binance Mainnet";

export const CONTRACT_ADDRESS = "0x8D95b3E081dEEDD61A3Bdb61b107543e94eF496A";
export const CONTRACT_ABI = contractAbi;

export const TOKEN_CONTRACT_ADDRESS = "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3";
export const TOKEN_CONTRACT_ABI = tokenAbi;

export const TOKEN_SYMBOL = "DAI";
export const TOKEN_DECIMALS = 18;
export const WEBSITE_URL = "https://DAI.jorjinvest.net";
export const API_URL = "https://DAI.jorjinvest.net/api/";
